import React from "react"

import styles from "./index.module.css"
import cn from "classnames";
import {NavLink} from "react-router-dom";

export const Header = () => {
    return <header className={styles.Wrapper}>
        <div className={cn("center-container", styles.Inner)}>
            <h2 className={styles.Logo}><NavLink to="/" className={styles.Link}>Steam currency</NavLink></h2>
            <div className={styles.RightBlock}>
                <NavLink to="/exchangeRate"
                    /* className={(navData) => (navData.isActive ? styles.ActiveLink : styles.Link)}  */
                         className={styles.Link}>
                    Как проверить курс
                </NavLink>
                <NavLink to="/gratitude"
                    /* className={(navData) => (navData.isActive ? styles.ActiveLink : styles.Link)}  */
                         className={styles.Link}>
                    Поддержать проект
                </NavLink>
                <NavLink to="/faq"
                    /* className={(navData) => (navData.isActive ? styles.ActiveLink : styles.Link)}  */
                         className={styles.Link}>
                    FAQ
                </NavLink>
            </div>

        </div>
    </header>
}
