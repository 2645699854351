import axios from "axios";

const URL = "https://api.steam-currency.ru/";
/*const API_KEY = "bc8edd7a-1cd6-4f52-81e7-dff6464c7445";*/

const instanceAxios = axios.create({
    withCredentials: true,
    baseURL: URL,
    /*headers: {
        "API-KEY": API_KEY,
    },*/
});

export const api = {
    getCurrencyList() {
        return (
            instanceAxios.get("currency")
                .then((response) => {
                    return response.data;
                })
        );
    },
    getCurrencyRate(currencyPair = "USD:RUB") {
        return (
            instanceAxios.get(`currency/${currencyPair}`)
                .then((response) => {
                    return response.data;
                })
        );
    }
}
